        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="12"
                                        >
                            <html-page
  api="https://api.vestaorganic.ru/api/v1/htm/lk/service/data/page"
/>            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'


                                      import HtmlPage from '@core/components/html-page/HtmlPage.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                          HtmlPage,
                                          },
  setup() {
    
      },
}
</script>
    